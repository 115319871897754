<template>
  <div>
    <v-app-bar app class="elevation-0" color="#FFFFFF" height="62.5">
      <v-app-bar-nav-icon
        @click.stop="navigation = !navigation"
      ></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <div>
        <v-menu open-on-hover nudge-bottom>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <span class="mr-2" v-if="!$vuetify.breakpoint.xsOnly">{{
                g_user.name
              }}</span>
              <v-avatar v-if="g_user.avatar" class="mx-2">
                <img :src="g_user.avatar" :alt="`${g_user.name} Avatar`" />
              </v-avatar>
              <v-icon size="25" color="black">mdi-menu-down</v-icon>
            </div>
          </template>
          <v-list>
            <v-list-item @click="dialogChangePassword = true">
              <v-list-item-icon><v-icon>mdi-lock</v-icon></v-list-item-icon>
              <v-list-item-title>Change Password</v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout">
              <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>

    <v-navigation-drawer
      v-model="navigation"
      :permanent="isMob ? false : navigation"
      app
    >
      <v-list-item>
        <v-list-item-icon>
          <v-img
            src="@/assets/logo.png"
            max-height="100%"
            max-width="30px"
          ></v-img>
        </v-list-item-icon>
        <v-list-item-title class="font-weight-black body-1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">{{ g_app_name }}</span>
            </template>
            <span>{{ g_app_name }}</span>
          </v-tooltip>
        </v-list-item-title>
      </v-list-item>

      <v-divider class="white"></v-divider>

      <v-list dense nav>
        <span v-for="(item, i) in menuItems" :key="i">
          <v-list-item
            v-if="!item.children"
            :class="isActiveMenu(item.title) ? 'primary elevation-0' : ''"
            :dark="isActiveMenu(item.title)"
            link
            @click="toModule(item)"
          >
            <v-list-item-icon>
              <v-icon :color="isActiveMenu(item.title) ? 'white' : ''">{{
                item.icon
              }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group v-else :prepend-icon="item.icon" no-action>
            <template v-slot:activator>
              <v-list-item-content
                :color="isActiveMenu(item.title) ? 'white' : ''"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="(child, i) in item.children"
              :key="i"
              :class="isActiveMenu(child.title) ? 'primary elevation-0' : ''"
              :dark="isActiveMenu(child.title)"
              link
              @click="toModule(child)"
            >
              <v-list-item-title v-text="child.title"></v-list-item-title>
              <v-list-item-icon>
                <v-icon
                  v-text="child.icon"
                  :color="isActiveMenu(item.title) ? 'white' : ''"
                ></v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </span>
      </v-list>
    </v-navigation-drawer>

    <ChangePassword
      :dialogChangePassword="dialogChangePassword"
      :closeDialogChangePassword="() => (dialogChangePassword = false)"
    />

    <v-main app style="background: #f8f8f8">
      <div class="ma-4">
        <slot />
      </div>
    </v-main>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { admin, teacher } from "../utils/menu";

import ChangePassword from "../components/ChangePassword.vue";

export default {
  components: {
    ChangePassword,
  },
  data() {
    return {
      menuItems: [],
      right: null,
      navigation: true,
      modalPassword: false,
      dialogChangePassword: false,
    };
  },
  computed: {
    ...mapGetters(["g_app_name", "g_user", "g_roles", "g_active_menu"]),
    isMob() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  mounted() {
    // this.$socket.open();
  },
  created() {
    switch (this.g_roles) {
      case "ADMIN":
        this.menuItems = admin.filter((d) => !d.hidden);
        break;
      default:
        this.menuItems = teacher.filter((d) => !d.hidden);
        break;
    }

    if (this.isMob) {
      this.navigation = false;
    }
  },
  methods: {
    toModule(item) {
      this.$store.commit("SET_ACTIVE_MENU", item.title);
      if (this.isMob) this.navigation = !this.navigation;
      if (this.$route.name !== item.name)
        this.$router.push({ name: item.name });
    },
    logout() {
      this.$store.dispatch("LogOut").then(() => {
        this.$socket.disconnect();
        window.location.reload();
      });
    },
    isActiveMenu(title) {
      return title === this.g_active_menu;
    },
  },
};
</script>
