export const admin = [
  // {
  //   title: "Dashboard",
  //   icon: "mdi-view-dashboard",
  //   name: "Dashboard",
  //   parent: "dashboard",
  //   hidden: false,
  // },
  {
    title: "User List",
    icon: "mdi-account",
    name: "MasterUser",
    parent: "master",
    hidden: false,
  },
  {
    title: "Master Category",
    icon: "mdi-file-document",
    name: "MasterCategory",
    parent: "master",
    hidden: false,
  },
  {
    title: "Catalog",
    icon: "mdi-clipboard-text",
    name: "Catalog",
    parent: "catalog",
    hidden: false,
  },
  {
    title: "Gallery",
    icon: "mdi-file-image",
    name: "Gallery",
    parent: "gallery",
    hidden: false,
  },
  {
    title: "Orders",
    icon: "mdi-cart",
    name: "Orders",
    parent: "orders",
    hidden: false,
  },
  {
    title: "Settings",
    icon: "mdi-information-outline",
    name: "Settings",
    parent: "settings",
    hidden: false,
    children: [
      {
        title: "Bank Account",
        name: "BankAccount",
        parent: "settings",
        hidden: false,
      },
      {
        title: "Information",
        name: "Information",
        parent: "settings",
        hidden: false,
      },
      {
        title: "Cover",
        name: "Cover",
        parent: "settings",
        hidden: false,
      },
      {
        title: "Slide Images",
        name: "SlideImages",
        parent: "settings",
        hidden: false,
      },
    ],
  },
  // {
  //   title: "Master",
  //   icon: "mdi-file-document",
  //   name: "Master",
  //   parent: "master",
  //   hidden: false,
  //   children: [
  //     {
  //       title: "Tahun Ajaran",
  //       name: "MasterSchoolYear",
  //       parent: "master",
  //       hidden: false,
  //     },
  //     {
  //       title: "Kelas",
  //       name: "MasterClass",
  //       parent: "master",
  //       hidden: false,
  //     },
  //     {
  //       title: "Mata Pelajaran",
  //       name: "MasterSubject",
  //       parent: "master",
  //       hidden: false,
  //     },
  //     {
  //       title: "Siswa",
  //       name: "MasterStudent",
  //       parent: "master",
  //       hidden: false,
  //     },
  //     {
  //       title: "Guru",
  //       name: "MasterTeacher",
  //       parent: "master",
  //       hidden: false,
  //     },
  //   ],
  // },
  // {
  //   title: "E-Test",
  //   icon: "mdi-clipboard-text",
  //   name: "E-Test",
  //   parent: "etest",
  //   hidden: false,
  // },
  // {
  //   title: "E-Learning",
  //   icon: "mdi-view-dashboard",
  //   name: "ElearningList",
  //   parent: "elearning",
  //   hidden: false,
  // },
];

export const teacher = [
  {
    title: "Dashboard",
    icon: "mdi-view-dashboard",
    name: "Dashboard",
    parent: "dashboard",
    hidden: false,
  },
  {
    title: "Siswa",
    icon: "mdi-file-document",
    name: "MasterStudent",
    parent: "master",
    hidden: false,
  },
  {
    title: "E-Test",
    icon: "mdi-clipboard-text",
    name: "E-Test",
    parent: "etest",
    hidden: false,
  },
  {
    title: "E-Learning",
    icon: "mdi-view-dashboard",
    name: "ElearningList",
    parent: "elearning",
    hidden: false,
  },
];
