import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
// import store from '@/store';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: "#424242",
        secondary: "#07294D",
        accent: "#5089C6",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFA000",
        grey: "#F8F8F8",
      },
      dark: {
        primary: "#E3B105",
        secondary: "#07294D",
        accent: "#5089C6",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFA000",
        grey: "#F8F8F8",
      },
    },
  },
});
