const app = {
  state: {
    app_name: process.env.VUE_APP_NAME,
    snackbar: false,
    snackbar_text: "",
    snackbar_color: "success",
    global_loading: false,
  },
  mutations: {
    SET_GLOBAL_LOADING: (state, value) => {
      state.global_loading = value;
    },
    SET_SNACKBAR: (state, data) => {
      state.snackbar = false;
      state.snackbar_text = "";

      setTimeout(() => {
        state.snackbar = data.value;
        state.snackbar_text = data.text;
        state.snackbar_color = data.color || "success";
      }, 100);
    },
  },
};

export default app;
