import Cookies from "js-cookie";

export function getToken() {
  return Cookies.get("token");
}

export function setToken(token) {
  return Cookies.set("token", token, {
    expires: 172800,
  });
}

export function removeToken() {
  return Cookies.remove("token");
}
