<template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <v-card>
      <v-card-title class="text-h5">
        {{ title }}
      </v-card-title>
      <v-card-text>{{ text }}</v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          :disabled="loading"
          color="primary"
          outlined
          @click="action(false)"
        >
          Cancel
        </v-btn>
        <v-btn
          :color="isDelete ? 'error' : 'primary'"
          :loading="loading"
          depressed
          @click="action(true)"
        >
          {{ isDelete ? "Delete" : "Save" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
    title: String,
    text: String,
    type: String,
    loading: Boolean,
  },
  computed: {
    isDelete() {
      return this.type === "delete";
    },
  },
  methods: {
    action(value) {
      this.$emit("action", value);
    },
  },
};
</script>
