<template>
  <v-app>
    <v-overlay absolute opacity="0.4" z-index="1000" :value="globalLoading">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <snackbar></snackbar>

    <component :is="layout">
      <router-view />
    </component>
  </v-app>
</template>

<script>
import Snackbar from "./components/Snackbar.vue";

export default {
  name: "App",
  components: {
    Snackbar,
  },
  data: () => ({
    defaultLayout: "default",
  }),
  computed: {
    globalLoading() {
      return this.$store.getters.g_global_loading;
    },
    layout() {
      return `${this.$route.meta.layout || this.defaultLayout}-layout`;
    },
  },
  mounted() {
    console.info("v1.10.2");
  },
};
</script>

<style lang="scss">
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
