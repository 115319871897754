<template>
  <div class="text-center">
    <v-snackbar :value="snackbar" :color="snackbarColor" dark>
      <span class="white--text">{{ snackbarText }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="closeSnackbar"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import store from "../store";

export default {
  computed: {
    snackbar() {
      return store.getters.g_snackbar;
    },
    snackbarText() {
      return store.getters.g_snackbar_text;
    },
    snackbarColor() {
      return store.getters.g_snackbar_color;
    },
  },
  methods: {
    closeSnackbar() {
      store.commit("SET_SNACKBAR", { value: false });
    },
  },
};
</script>
