import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    meta: { layout: "default" },
    // route level code-splitting
    // this generates a separate chunk (login.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    meta: { layout: "default" },
    component: () =>
      import(
        /* webpackChunkName: "ForgotPassword" */ "../views/ForgotPassword.vue"
      ),
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    meta: { layout: "default" },
    component: () =>
      import(
        /* webpackChunkName: "ResetPassword" */ "../views/ResetPassword.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
