const getters = {
  // App
  g_app_name: (state) => state.app.app_name,
  g_global_loading: (state) => state.app.global_loading,
  g_snackbar: (state) => state.app.snackbar,
  g_snackbar_text: (state) => state.app.snackbar_text,
  g_snackbar_color: (state) => state.app.snackbar_color,
  // User
  g_roles: (state) => state.user.roles,
  g_token: (state) => state.user.token,
  g_user: (state) => state.user.user,
  g_menu: (state) => state.user.menu,
  g_admin_first_page: (state) => state.user.admin_first_page,
  g_active_menu: (state) => state.user.active_menu,
};

export default getters;
