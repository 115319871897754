<template>
  <div>
    <v-dialog v-model="dialogChangePassword" persistent max-width="350">
      <v-card class="rounded-lg">
        <v-card-text class="mt-3 pb-2">
          <div class="title font-weight-bold text-center pt-6">
            Ganti Password
          </div>
          <v-form
            ref="formChangePassword"
            v-model="valid"
            lazy-validation
            class="mt-3"
          >
            <v-row>
              <!-- <v-col cols="12">
                <v-text-field
                  v-model="password.old_password"
                  :append-icon="spOld ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="spOld ? 'text' : 'password'"
                  :rules="[(v) => !!v || 'Password lama harus diisi']"
                  label="Password Lama"
                  hide-details="auto"
                  autocomplete
                  outlined
                  dense
                  v-on:keyup.enter="validate"
                  @click:append="spOld = !spOld"
                />
              </v-col> -->
              <v-col cols="12">
                <v-text-field
                  v-model="password.new_password"
                  :append-icon="spNew ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="spNew ? 'text' : 'password'"
                  :rules="[
                    (v) => !!v || 'Password baru harus diisi',
                    (v) =>
                      (!!v && v.length > 7) || 'Password minimal 8 karakter',
                  ]"
                  label="Password Baru"
                  hide-details="auto"
                  autocomplete
                  outlined
                  dense
                  v-on:keyup.enter="validate"
                  @click:append="spNew = !spNew"
                />
              </v-col>
              <!-- <v-col cols="12">
                <v-text-field
                  v-model="password.new_password_confirm"
                  :append-icon="spConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="spConfirm ? 'text' : 'password'"
                  :rules="[
                    (v) => !!v || 'Konfirmasi password baru harus diisi',
                    (v) => v == password.new_password || 'Password tidak sama',
                  ]"
                  label="Konfirmasi Password Baru"
                  hide-details="auto"
                  autocomplete
                  outlined
                  dense
                  v-on:keyup.enter="validate"
                  @click:append="spConfirm = !spConfirm"
                />
              </v-col> -->
            </v-row>
          </v-form>

          <div class="d-flex mb-2 mt-5">
            <v-btn outlined @click="close" class="mr-2">Batal</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="warning"
              :disabled="!valid"
              :loading="loadingChangePassword"
              depressed
              @click="validate"
              >Simpan</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { userChangePassword } from "../api/user";

export default {
  props: {
    dialogChangePassword: Boolean,
    closeDialogChangePassword: Function,
  },
  data() {
    return {
      valid: true,
      spOld: false,
      spNew: false,
      spConfirm: false,
      error: "",
      loadingChangePassword: false,
      password: { new_password: "" },
      // password: {
      //   old_password: "",
      //   new_password: "",
      //   new_password_confirm: "",
      // },
    };
  },
  methods: {
    close() {
      if (this.$refs.formChangePassword) this.$refs.formChangePassword.reset();
      this.closeDialogChangePassword();
    },
    validate() {
      if (this.$refs.formChangePassword.validate()) {
        this.loadingChangePassword = true;
        userChangePassword(this.password)
          .then((res) => {
            if (res.data.code) {
              this.closeDialogChangePassword();
              this.$store.dispatch("LogOutFe");
              this.$store.commit("SET_SNACKBAR", {
                value: true,
                text: "Berhasil merubah password, silahkan login ulang menggunakan password baru anda",
                color: "success",
              });
            } else {
              this.$store.commit("SET_SNACKBAR", {
                value: true,
                text: res.data.code,
                color: "error",
              });
            }
            this.loadingChangePassword = false;
          })
          .catch(() => {
            this.loadingChangePassword = false;
          });
      }
    },
  },
};
</script>
