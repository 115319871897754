<template>
  <div>
    <v-app-bar app class="elevation-0" color="#FFFFFF" height="62.5">
      <v-img
        src="@/assets/logo.png"
        max-height="100%"
        max-width="30px"
        class="mr-6"
      ></v-img>
      <v-app-bar-title class="font-weight-black body-1">{{
        g_app_name
      }}</v-app-bar-title>
      <v-spacer></v-spacer>
      <div>
        <v-menu open-on-hover nudge-bottom>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <span class="mr-2" v-if="!$vuetify.breakpoint.xsOnly">{{
                g_user.person.name
              }}</span>
              <v-avatar v-if="g_user.user.avatar" class="mx-2">
                <img
                  :src="g_user.user.avatar"
                  :alt="`${g_user.person.name} Avatar`"
                />
              </v-avatar>
              <v-icon size="25" color="black">mdi-menu-down</v-icon>
            </div>
          </template>
          <v-list>
            <v-list-item @click="dialogChangePassword = true">
              <v-list-item-icon><v-icon>mdi-lock</v-icon></v-list-item-icon>
              <v-list-item-title>Ganti Password</v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout">
              <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>

    <ChangePassword
      :dialogChangePassword="dialogChangePassword"
      :closeDialogChangePassword="() => (dialogChangePassword = false)"
    />

    <v-main app style="background: #f8f8f8" class="ma-5">
      <div class="ma-4">
        <slot />
      </div>
    </v-main>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { admin, teacher } from "../utils/menu";

import ChangePassword from "../components/ChangePassword.vue";

export default {
  components: {
    ChangePassword,
  },
  data() {
    return {
      menuItems: [],
      right: null,
      navigation: false,
      modalPassword: false,
      dialogChangePassword: false,
    };
  },
  computed: {
    ...mapGetters(["g_app_name", "g_user", "g_roles", "g_active_menu"]),
    isMob() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  created() {
    switch (this.g_roles) {
      case "ADMIN":
        this.menuItems = admin.filter((d) => !d.hidden);
        break;
      default:
        this.menuItems = teacher.filter((d) => !d.hidden);
        break;
    }
  },
  mounted() {
    this.$socket.open();
  },
  methods: {
    toModule(item) {
      this.$store.commit("SET_ACTIVE_MENU", item.title);
      if (this.isMob) this.navigation = !this.navigation;
      if (this.$route.name !== item.name)
        this.$router.push({ name: item.name });
    },
    logout() {
      this.$store.dispatch("LogOut").then(() => {
        this.$socket.disconnect();
        window.location.reload();
      });
    },
    isActiveMenu(title) {
      return title === this.g_active_menu;
    },
  },
};
</script>
