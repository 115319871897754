import router from "../router";

import { userLogin, userProfile, userLogout } from "../api/user";
import { getToken, setToken } from "../utils/storage";
import { admin, teacher, student } from "@/router/routerList";

const user = {
  state: {
    roles: "",
    token: getToken(),
    user: {},
    menu: [],
    admin_first_page: "",
    active_menu: "",
  },

  mutations: {
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
      setToken(token);
    },
    SET_USER: (state, value) => {
      state.user = value;
    },
    SET_MENU: (state, value) => {
      state.menu = value;
    },
    SET_ADMIN_FIRST_PAGE: (state, value) => {
      state.admin_first_page = value;
    },
    SET_ACTIVE_MENU: (state, value) => {
      state.active_menu = value;
    },
  },
  actions: {
    // login
    Login({ commit }, userInfo) {
      const email = userInfo.email.trim();
      return new Promise((resolve, reject) => {
        userLogin(email, userInfo.password)
          .then((res) => {
            if (res.data.code) {
              const { data } = res.data;
              commit("SET_TOKEN", data.api_token);
            }
            resolve(res);
          })
          .catch((error) => {
            reject(error.response);
          });
      });
    },
    GetUserInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        userProfile(state.token)
          .then(async (res) => {
            if (res.data.code) {
              const { data } = res.data;

              commit("SET_ROLES", data.role);
              commit("SET_USER", data);

              switch (data.role) {
                case "ADMIN":
                  commit("SET_MENU", admin);
                  state.user = data;
                  commit("SET_ADMIN_FIRST_PAGE", "/"); // Set Initial Router
                  break;
                case "TEACHER": //currently not available
                  commit("SET_MENU", teacher);
                  commit("SET_ADMIN_FIRST_PAGE", "/"); // Set Initial Router
                  break;
                case "STUDENT": //currently not available
                  commit("SET_MENU", student);
                  commit("SET_ADMIN_FIRST_PAGE", "/"); // Set Initial Router
                  break;
              }
            } else {
              reject();
            }
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    LogOut({ commit }) {
      return new Promise((resolve) => {
        commit("SET_GLOBAL_LOADING", true);
        userLogout()
          .then((res) => {
            if (res.data.code) {
              commit("SET_TOKEN", "");
              commit("SET_ROLES", "");
              commit("SET_USER", {});
              commit("SET_MENU", {});
              router.replace({ path: "/login" });
              resolve();
            } else {
              router.replace({ path: "/login" });
            }
            commit("SET_GLOBAL_LOADING", false);
          })
          .catch(() => {
            setTimeout(() => {
              commit("SET_TOKEN", "");
              commit("SET_ROLES", "");
              commit("SET_USER", {});
              commit("SET_MENU", {});
              commit("SET_GLOBAL_LOADING", false);
              router.replace({ path: "/login" });
              resolve();
            }, 1000);
          });
      });
    },
    LogOutFe({ commit }) {
      return new Promise((resolve) => {
        commit("SET_GLOBAL_LOADING", true);
        setTimeout(() => {
          commit("SET_TOKEN", "");
          commit("SET_ROLES", "");
          commit("SET_USER", {});
          commit("SET_MENU", {});
          commit("SET_GLOBAL_LOADING", false);
          router.replace({ path: "/login" });
          resolve();
        }, 1000);
      });
    },
  },
};

export default user;
