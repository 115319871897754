<template>
  <v-main style="background: #f8f8f8">
    <slot />
  </v-main>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      menuItems: [
        { title: "Dashboard", icon: "mdi-view-dashboard" },
        { title: "Photos", icon: "mdi-image" },
        { title: "About", icon: "mdi-help-box" },
      ],
      right: null,
    };
  },
  computed: {
    ...mapGetters(["g_app_name"]),
  },
};
</script>
