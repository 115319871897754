export const admin = [
  {
    path: "/",
    name: "Dashboard",
    meta: { layout: "admin", roles: ["admin"], parent: "dashboard" },
    component: () => import("../views/Dashboard/index.vue"),
  },
  // Master
  {
    path: "/master-category",
    name: "MasterCategory",
    meta: { layout: "admin", roles: ["admin"], parent: "master" },
    component: () => import("@/views/master/MasterCategory.vue"),
  },
  {
    path: "/master-user",
    name: "MasterUser",
    meta: { layout: "admin", roles: ["admin"], parent: "master" },
    component: () => import("@/views/master/MasterUser.vue"),
  },
  // {
  //   path: "/master/school-year",
  //   name: "MasterSchoolYear",
  //   meta: { layout: "admin", roles: ["admin"], parent: "master" },
  //   component: () =>
  //     import("@/views/master/MasterSchoolYear/MasterSchoolYear.vue"),
  // },
  // {
  //   path: "/master/class",
  //   name: "MasterClass",
  //   meta: { layout: "admin", roles: ["admin"], parent: "master" },
  //   component: () => import("@/views/master/MasterClass/MasterClass.vue"),
  // },
  // {
  //   path: "/master/subject",
  //   name: "MasterSubject",
  //   meta: { layout: "admin", roles: ["admin"], parent: "master" },
  //   component: () => import("@/views/master/MasterSubject/MasterSubject.vue"),
  // },
  // {
  //   path: "/master/student",
  //   name: "MasterStudent",
  //   meta: { layout: "admin", roles: ["admin"], parent: "master" },
  //   component: () => import("@/views/master/MasterStudent/MasterStudent.vue"),
  // },
  // {
  //   path: "/master/teacher",
  //   name: "MasterTeacher",
  //   meta: { layout: "admin", roles: ["admin"], parent: "master" },
  //   component: () => import("@/views/master/MasterTeacher/MasterTeacher.vue"),
  // },
  // Etest
  // {
  //   path: "/etest/list",
  //   name: "E-Test",
  //   meta: { layout: "admin", roles: ["admin"], parent: "etest" },
  //   component: () => import("../views/etest/ListEtest.vue"),
  // },
  // {
  //   path: "/etest/create-etest",
  //   name: "CreateE-Test",
  //   meta: { layout: "admin", roles: ["admin"], parent: "etest" },
  //   component: () => import("../views/etest/CreateEtest.vue"),
  // },
  // {
  //   path: "/etest/value-list",
  //   name: "ValueList",
  //   meta: { layout: "admin", roles: ["admin"], parent: "etest" },
  //   component: () => import("../views/etest/ValueList.vue"),
  // },
  // {
  //   path: "/etest/correction-etest",
  //   name: "CorrectionEtest",
  //   meta: { layout: "admin", roles: ["admin"], parent: "etest" },
  //   component: () => import("../views/etest/CorrectionEtest.vue"),
  // },
  // {
  //   path: "/etest/view-etest",
  //   name: "ViewEtest",
  //   meta: { layout: "admin", roles: ["admin"], parent: "etest" },
  //   component: () => import("../views/etest/ViewEtest.vue"),
  // },
  {
    path: "/catalog",
    name: "Catalog",
    meta: { layout: "admin", roles: ["admin"], parent: "catalog" },
    component: () => import("@/views/Catalog"),
  },
  {
    path: "/gallery",
    name: "Gallery",
    meta: { layout: "admin", roles: ["admin"], parent: "gallery" },
    component: () => import("@/views/Gallery"),
  },
  {
    path: "/orders",
    name: "Orders",
    meta: { layout: "admin", roles: ["admin"], parent: "orders" },
    component: () => import("@/views/Orders"),
  },
  {
    path: "/settings/bank-account",
    name: "BankAccount",
    meta: { layout: "admin", roles: ["admin"], parent: "settings" },
    component: () => import("@/views/Settings/BankAccount"),
  },
  {
    path: "/settings/information",
    name: "Information",
    meta: { layout: "admin", roles: ["admin"], parent: "settings" },
    component: () => import("@/views/Settings/Information"),
  },
  {
    path: "/settings/cover",
    name: "Cover",
    meta: { layout: "admin", roles: ["admin"], parent: "settings" },
    component: () => import("@/views/Settings/Cover"),
  },
  {
    path: "/settings/slide-images",
    name: "SlideImages",
    meta: { layout: "admin", roles: ["admin"], parent: "settings" },
    component: () => import("@/views/Settings/SlideImages"),
  },
  // {
  //   path: "/e-learning",
  //   name: "ElearningList",
  //   meta: { layout: "admin", roles: ["admin"], parent: "elearning" },
  //   component: () => import("@/views/Elearning/ElearningList.vue"),
  // },
  // {
  //   path: "/e-learning-form",
  //   name: "ElearningForm",
  //   meta: { layout: "admin", roles: ["admin"], parent: "elearning" },
  //   component: () => import("@/views/Elearning/ElearningForm.vue"),
  // },
  // {
  //   path: "/e-learning-detail",
  //   name: "ElearningDetail",
  //   meta: { layout: "admin", roles: ["admin"], parent: "elearning" },
  //   component: () => import("@/views/Elearning/ElearningDetail.vue"),
  // },
  // {
  //   path: "/dashboard-student",
  //   name: "DashboardStudent",
  //   meta: { roles: ["admin"] },
  //   component: () => import("@/views/Dashboard/DashboardStudent.vue"),
  // },
  // {
  //   path: "/edit-etest",
  //   name: "EditEtest",
  //   meta: { layout: "admin", roles: ["admin"] },
  //   component: () => import("@/views/etest/EditEtest.vue"),
  // },
];

export const teacher = [
  {
    path: "/",
    name: "Dashboard",
    meta: { layout: "admin", roles: ["teacher"], parent: "dashboard" },
    component: () => import("../views/Dashboard/index.vue"),
  },
  // Master
  {
    path: "/master/student",
    name: "MasterStudent",
    meta: { layout: "admin", roles: ["teacher"], parent: "master" },
    component: () => import("@/views/master/MasterStudent/MasterStudent.vue"),
  },
  // Etest
  {
    path: "/etest/list",
    name: "E-Test",
    meta: { layout: "admin", roles: ["teacher"], parent: "etest" },
    component: () => import("../views/etest/ListEtest.vue"),
  },
  {
    path: "/etest/create-etest",
    name: "CreateE-Test",
    meta: { layout: "admin", roles: ["teacher"], parent: "etest" },
    component: () => import("../views/etest/CreateEtest.vue"),
  },
  {
    path: "/etest/value-list",
    name: "ValueList",
    meta: { layout: "admin", roles: ["teacher"], parent: "etest" },
    component: () => import("../views/etest/ValueList.vue"),
  },
  {
    path: "/etest/view-etest",
    name: "ViewEtest",
    meta: { layout: "admin", roles: ["teacher"], parent: "etest" },
    component: () => import("../views/etest/ViewEtest.vue"),
  },
  {
    path: "/etest/correction-etest",
    name: "CorrectionEtest",
    meta: { layout: "admin", roles: ["teacher"], parent: "etest" },
    component: () => import("../views/etest/CorrectionEtest.vue"),
  },
  {
    path: "/e-learning",
    name: "ElearningList",
    meta: { layout: "admin", roles: ["teacher"], parent: "elearning" },
    component: () => import("@/views/Elearning/ElearningList.vue"),
  },
  {
    path: "/e-learning-detail",
    name: "ElearningDetail",
    meta: { layout: "admin", roles: ["admin"], parent: "elearning" },
    component: () => import("@/views/Elearning/ElearningDetail.vue"),
  },
  {
    path: "/e-learning-form",
    name: "ElearningForm",
    meta: { layout: "admin", roles: ["teacher"], parent: "elearning" },
    component: () => import("@/views/Elearning/ElearningForm.vue"),
  },
  {
    path: "/dashboard-student",
    name: "DashboardStudent",
    meta: { roles: ["teacher"] },
    component: () => import("@/views/Dashboard/DashboardStudent.vue"),
  },
  {
    path: "/etest-history",
    name: "EtestHistory",
    meta: { roles: ["teacher"] },
    component: () => import("@/views/etest/EtestHistory.vue"),
  },
  {
    path: "/do-etest",
    name: "DoEtest",
    meta: { roles: ["teacher"] },
    component: () => import("@/views/etest/DoEtest.vue"),
  },
  {
    path: "/edit-etest",
    name: "EditEtest",
    meta: { layout: "admin", roles: ["teacher"] },
    component: () => import("@/views/etest/EditEtest.vue"),
  },
];

export const student = [
  {
    path: "/",
    name: "Dashboard",
    meta: { layout: "student", roles: ["student"], parent: "dashboard" },
    component: () => import("../views/Dashboard/index.vue"),
  },
  // Elearning
  {
    path: "/e-learning-detail",
    name: "ElearningDetail",
    meta: { layout: "student", roles: ["student"], parent: "elearning" },
    component: () => import("@/views/Elearning/ElearningDetail.vue"),
  },
  {
    path: "/do-etest",
    name: "DoEtest",
    meta: { layout: "student", roles: ["student"] },
    component: () => import("@/views/etest/DoEtest.vue"),
  },
  {
    path: "/etest-history",
    name: "EtestHistory",
    meta: { layout: "student", roles: ["student"] },
    component: () => import("@/views/etest/EtestHistory.vue"),
  },
  {
    path: "/etest/correction-etest",
    name: "CorrectionEtest",
    meta: { layout: "student", roles: ["student"] },
    component: () => import("../views/etest/CorrectionEtest.vue"),
  },
  {
    path: "/etest/last-etest",
    name: "LastEtest",
    meta: { layout: "student", roles: ["student"] },
    component: () => import("../views/etest/LastEtestDetail.vue"),
  },
  {
    path: "/etest/last-elearning",
    name: "LastElearning",
    meta: { layout: "student", roles: ["student"] },
    component: () => import("../views/etest/LastElearningDetail.vue"),
  },
];
