import router from "./router";
import store from "./store";
import { getToken } from "./utils/storage";

import { admin, teacher } from "./utils/menu";

function hasPermission(permissionRoles, path) {
  return permissionRoles.find((role) => path === role.path);
}

const whiteList = ["/login", "/signup", "/forgot-password", "/reset-password"]; // no redirect whitelist

router.beforeEach((to, _, next) => {
  store.commit("SET_GLOBAL_LOADING", true);
  if (getToken()) {
    if (to.path === "/login") {
      next({ path: "/" });
      store.commit("SET_GLOBAL_LOADING", false);
    } else if (!store.getters.g_roles) {
      store
        .dispatch("GetUserInfo")
        .then(() => {
          router.addRoutes(store.getters.g_menu);

          next({ ...to, replace: true });
        })
        .catch(() => {
          // store.dispatch("LogOut").then(() => {
          //   window.location.reload();
          // });
        });
    } else if (hasPermission(store.getters.g_menu, to.path)) {
      next();
    } else {
      next({ path: "/", replace: true });
    }

    // else if (noRoute(store.getters.g_menu, to.path)) {
    //   console.log(to.path);
    //   next({ path: '/' });
    // }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next("/login");
    }
    store.commit("SET_GLOBAL_LOADING", false);
  }
});

router.afterEach((to) => {
  if (
    store.getters.g_roles === "ADMIN" ||
    store.getters.g_roles === "TEACHER"
  ) {
    const mapingAdmin = [];

    admin.map((d) => {
      if (d.children) {
        d.children.map((c) => mapingAdmin.push(c));
      } else {
        mapingAdmin.push(d);
      }
    });

    store.commit(
      "SET_ACTIVE_MENU",
      mapingAdmin.find((d) => d.name === to.name)
        ? mapingAdmin.find((d) => d.name === to.name).title
        : ""
    ); // Set Initial Active Menu
  } else {
    store.commit(
      "SET_ACTIVE_MENU",
      teacher.find((d) => d.parent === to.meta.parent)
        ? teacher.find((d) => d.parent === to.meta.parent).title
        : ""
    ); // Set Initial Active Menu
  }
  store.commit("SET_GLOBAL_LOADING", false);
});
